<template lang="pug">
div.container
  div.row.justify-content-center
    h1 Here Be Dragons
  div.row.justify-content-center
    p Don't mess anything up. No seatbelts!
  br/
  br/
  div.row.justify-content-center
    b-alert(variant="warning" v-show="email.type == 'editToken' || email2.type == 'editToken'" show) Achtung! Sending an edit token doesn't work if it doesn't exist for this user. It doesn't get generated when debug-sending that email. Check server logs for errors. There are better ways to debug that particular scenario.
  div.row.justify-content-center
    div.col-md-8
      h4 This will fetch the details of a member and send them an email of your choice.
      b-form(inline)
        b-form-group(label="Member ID" laber-for="id")
          b-form-input(type="text" name="id" v-model="email.id")
        b-form-group(label="Email type" laber-for="membershipType")
          b-form-select(v-model="email.type")
            option(value="new") New member
            option(value="willExpire") About to expire
            option(value="expired") Expired
            option(value="renewed") Renewed
            option(value="editToken") Edit link
            option(value="newAddress") New email address
        b-form-group
          b-button(@click="sendEmail(email)") Send

  br/
  div.row.justify-content-center
    div.col-md-8
      h4 Send monthly emails immediately. <b-badge variant="danger" v-show="!globalState.isProductionEnv">don't fuck with this button</b-badge>
      b-button(variant="warning" @click="triggerEmails()") Trigger Monthly Emails
  br/
  div.row.justify-content-center
    div.col-md-8
      h4 Trigger monthly card order immediately.
      b-button(variant="warning" @click="triggerCards()") Trigger Cards
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      email: {
        id: null,
        type: null
      },
      email2: {
        id: null,
        type: null
      },
      users: null,
      globalState: globalState
    }
  },
  methods: {
    sendEmail: function (email) {
      this.axios.get('/api/individual-members/' + email.id)
        .then(res => {
          if (email.to) { res.data.email = email.to }

          this.axios.post('/api/debug/email/', { member: res, type: email.type })
            .then(res2 => (this.$notify({
              group: 'success',
              type: 'success',
              title: 'Email sent'
            })))
            .catch(err => (this.$notify({
              group: 'error',
              type: 'error',
              title: 'Sending email failed',
              text: err.response.data,
              duration: -1
            })))
        })
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching member details for email failed',
          text: err.response.data,
          duration: -1
        })))
    },
    triggerEmails: function () {
      this.axios.get('/api/debug/monthly-emails/')
        .then(res => (this.$notify({
          group: 'success',
          type: 'success',
          title: 'Emails triggered'
        })))
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Triggering emails failed',
          text: err.response.data,
          duration: -1
        })))
    },
    triggerCards: function () {
      this.axios.get('/api/debug/process-cards/')
        .then(res => (this.$notify({
          group: 'success',
          type: 'success',
          title: 'Card order triggered'
        })))
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Triggering card order failed',
          text: err.response.data,
          duration: -1
        })))
    }
  }
}
</script>
